<template>
  <v-container class="is-md py-12">
    <v-row align="end" class="mb-3">
      <v-col>
        <h3 class="text-h6 font-weight-bold">
          Search users
        </h3>
        <p class="text-caption mb-0">
          Click on a user to inspect them further
        </p>
      </v-col>

      <v-spacer />

      <v-col class="d-flex align-center">
        <v-text-field
          v-model="filters.search" filled dense outlined
          prepend-inner-icon="mdi-magnify"
          class="mr-2"
          placeholder="id or name"
          hide-details
        />
        <v-btn
          light color="primary--gradient" :loading="loading.list"
          class="px-3 unwidth" @click="fetchApiData"
        >
          <v-icon class="text-h6">
            mdi-sync
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      class="data-table mb-8 mt-2"
      item-key="_id"
      :headers="headers"
      :items="entries"
      :options.sync="options"
      :footer-props="{ itemsPerPageOptions: [5, 10, 25] }"
      :server-items-length="total"
      :loading="loading.list"
      @click:row="onInspectUser"
    >
      <template #item.name="{ item }">
        <div class="d-flex align-center">
          <v-chip
            v-if="item.market"
            label small color="primary--gradient" light
            class="mr-2 px-2 font-weight-bold text-uppercase"
          >
            {{ item.market.slug }}
          </v-chip>
          <v-tooltip v-else top color="red">
            <template #activator="{ on }">
              <v-icon color="red" class="mr-2" v-on="on">
                mdi-alert
              </v-icon>
            </template>
            <span>Broken Market link! Fix ASAP!</span>
          </v-tooltip>

          <UserAvatar :user="item" rounded size="24" class="mr-2" />
          <Username :user="item">
            <template v-if="item.nameOverride" #suffix>
              <span class="text--secondary text-caption ml-1">({{ item.nameOverride }})</span>
            </template>
          </Username>
        </div>
      </template>
      <template #item.balance="{ value, item }">
        <span
          v-if="item.market"
          v-text="$helpers.getCurrencySymbol(item.market.currency)"
        />
        <span>{{ value | toScrap }}</span>
      </template>
      <template #item.invTotal="{ value, item }">
        <span
          v-if="item.market"
          v-text="$helpers.getCurrencySymbol(item.market.currency)"
        />
        <span>{{ value || 0 | toScrap }}</span>
      </template>
      <template #item.xp="{ item }">
        Lvl. {{ item.level }}
      </template>
      <template #item.createdAt="{ value }">
        <v-tooltip top color="primary500">
          <template #activator="{on}">
            <span v-on="on">{{ value | relativeTime('twitter') }}</span>
          </template>
          <span>{{ value | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { debounce } from '@/utils'
import { mapGetters } from 'vuex'
import { LEVELS } from '@/utils/constants'

export default {
  metaInfo: {
    title: 'User search',
  },
  data() {
    return {
      loading: {
        list: false,
      },
      entries: [],
      total: 0,
      filters: {
        search: '',
      },
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
        itemsPerPage: 10,
        mustSort: true,
        page: 1,
      },
      headers: [
        { text: 'User', value: 'name', align: 'start' },
        { text: 'Balance', value: 'balance' },
        { text: 'Inv value', value: 'invTotal' },
        {
          text: 'Lvl', value: 'xp', align: 'start', width: 80,
        },
        { text: 'Member since', value: 'createdAt', align: 'end' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
    }),
  },
  watch: {
    options: {
      handler() {
        if (!this.entries.length) this.fetchApiData()
        else this.onOptionsChanged()
      },
      deep: true,
    },
    filters: {
      handler() {
        this.options.page = 1
        this.onOptionsChanged()
      },
      deep: true,
    },
  },
  methods: {
    onOptionsChanged: debounce(function onOptionsChanged() { this.fetchApiData() }, 350),
    async fetchApiData() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options

      try {
        this.loading.list = true
        const { entries, total } = await this.$socket.request('admin.users.get', {
          page,
          filters: this.filters,
          sortBy: sortBy[0],
          asc: !sortDesc[0],
          limit: itemsPerPage,
        })

        this.entries = entries.map(i => ({ ...i, level: this.getLevel(i.xp) }))
        this.total = total
      } catch (error) {
        this.entries = []
        this.$toast.error(error.message)
      } finally {
        this.loading.list = false
      }
    },
    getLevel(xp) {
      const nextLevel = LEVELS.findIndex(lvl => lvl > xp ?? 0)

      // we return nextLevel because index starts at 0 and first level is 1
      return (nextLevel !== -1 ? nextLevel : LEVELS.length) - 1
    },
    onInspectUser(user) {
      this.$router.push({ name: 'Users inspect', params: { id: user._id } })
    },
  },
}
</script>

<style lang="scss" scoped>
.data-table ::v-deep {
  background-color: grey(600);

  .v-data-table-header {
    background-color: grey(500);
  }

  tbody tr {
    cursor: pointer;
  }

  .v-data-footer {
    background-color: grey(500);
  }
}

.pagination ::v-deep {
  .v-pagination__item,
  .v-pagination__navigation {
    background-color: grey(500);
    box-shadow: none;
  }
}
</style>
