var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"is-md py-12"},[_c('v-row',{staticClass:"mb-3",attrs:{"align":"end"}},[_c('v-col',[_c('h3',{staticClass:"text-h6 font-weight-bold"},[_vm._v(" Search users ")]),_c('p',{staticClass:"text-caption mb-0"},[_vm._v(" Click on a user to inspect them further ")])]),_c('v-spacer'),_c('v-col',{staticClass:"d-flex align-center"},[_c('v-text-field',{staticClass:"mr-2",attrs:{"filled":"","dense":"","outlined":"","prepend-inner-icon":"mdi-magnify","placeholder":"id or name","hide-details":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}}),_c('v-btn',{staticClass:"px-3 unwidth",attrs:{"light":"","color":"primary--gradient","loading":_vm.loading.list},on:{"click":_vm.fetchApiData}},[_c('v-icon',{staticClass:"text-h6"},[_vm._v(" mdi-sync ")])],1)],1)],1),_c('v-data-table',{staticClass:"data-table mb-8 mt-2",attrs:{"item-key":"_id","headers":_vm.headers,"items":_vm.entries,"options":_vm.options,"footer-props":{ itemsPerPageOptions: [5, 10, 25] },"server-items-length":_vm.total,"loading":_vm.loading.list},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.onInspectUser},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.market)?_c('v-chip',{staticClass:"mr-2 px-2 font-weight-bold text-uppercase",attrs:{"label":"","small":"","color":"primary--gradient","light":""}},[_vm._v(" "+_vm._s(item.market.slug)+" ")]):_c('v-tooltip',{attrs:{"top":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"red"}},on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v("Broken Market link! Fix ASAP!")])]),_c('UserAvatar',{staticClass:"mr-2",attrs:{"user":item,"rounded":"","size":"24"}}),_c('Username',{attrs:{"user":item},scopedSlots:_vm._u([(item.nameOverride)?{key:"suffix",fn:function(){return [_c('span',{staticClass:"text--secondary text-caption ml-1"},[_vm._v("("+_vm._s(item.nameOverride)+")")])]},proxy:true}:null],null,true)})],1)]}},{key:"item.balance",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(item.market)?_c('span',{domProps:{"textContent":_vm._s(_vm.$helpers.getCurrencySymbol(item.market.currency))}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm._f("toScrap")(value)))])]}},{key:"item.invTotal",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(item.market)?_c('span',{domProps:{"textContent":_vm._s(_vm.$helpers.getCurrencySymbol(item.market.currency))}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm._f("toScrap")(value || 0)))])]}},{key:"item.xp",fn:function(ref){
var item = ref.item;
return [_vm._v(" Lvl. "+_vm._s(item.level)+" ")]}},{key:"item.createdAt",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("relativeTime")(value,'twitter')))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("toDateFormat")(value,'YYYY-MM-DD HH:mm:ss')))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }